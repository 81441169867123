<template>
  <div>
    <bannerCommon :id="id" :banner-data="bannerData"></bannerCommon>
  </div>
</template>

<script>
import bannerCommon from './common/bannerCommon'
import to from 'await-to'
import { getCollegeBannerEditSearch } from '@/api/college'
export default {
  components: {
    bannerCommon,
  },
  data() {
    return {
      bannerData: {},
      id: this.$route.query.id,
    }
  },
  created() {
    this.getCollegeBannerEditSearchData()
  },
  methods: {
    async getCollegeBannerEditSearchData() {
      const [res, err] = await to(getCollegeBannerEditSearch({ id: this.id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.bannerData = res.data
    },
  },
}
</script>

<style scoped></style>
